<template>
  <section
    id="patientWhereSection"
    class="h-screen w-full flex flex-col gap-y-[16px]"
  >
    <!-- HEADER -->
    <header
      class="
        h-[66px]
        bg-white
        py-[24px]
        px-[54px]
        w-full
        flex flex-row
        gap-x-[8px]
      "
    >
      <a
        @click="$router.push('/patients')"
        class="text-[14px] text-[#9191a8] font-EffraR cursor-pointer"
        >Patientele</a
      >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m7.5 15 5-5-5-5"
          stroke="#767676"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="text-[14px] text-[#111111] font-EffraM" v-if="patient"
        >{{ patient.firstName }} {{ patient.lastName }}</span
      >
    </header>
    <!-- Content -->
    <main
      class="px-[16px] flex flex-col lg:flex-row items-start gap-x-[16px] gap-y-[16px] pb-5 lg:pb-0"
    >
      <!-- PATIENT INFORMATIONS -->
      <patient-information
        v-if="patient"
        :patient="patient"
        custom-class="w-full lg:w-[25%]"
      />
      <!-- CONTENT -->
      <div class="w-full lg:w-[75%] flex flex-col gap-y-4">
        <!-- NAVIGTOR -->
        <nav
          class="
            h-[55px]
            bg-white
            rounded-lg
            px-[24px]
            flex
            items-center
            gap-x-[24px]
            w-full overflow-y-auto
          "
        >
          <a
            v-for="link in sections"
            :key="link.id"
            class="
              border-0 border-b-[3px] border-solid text-base h-full whitespace-nowrap
              flex items-center gap-x-2 justify-center
            "
            :class="
              currentSection === link.id
                ? 'border-dokBlue-ultra text-dokBlue-ultra font-EffraM'
                : ' border-transparent text-[#767676] font-EffraR'
            "
            @click="currentSection = link.id"
            :href="
              `#${link.name
                .toLocaleLowerCase()
                .split(' ')
                .join('-')}`
            "
          >
            <span class="p-0 m-0  flex items-center" v-html="link.icon"></span>
            {{ link.name }}
          </a>
        </nav>
        <!-- View -->
        <div
          class="bg-white rounded-lg mb-10"
          :class="currentSection === 3 ? '' : 'p-4'"
        >
          <!-- Healthbook -->
          <patient-helthbook
            :patient="patient"
            v-if="currentSection === 1 && patient"
          />
          <!-- List Consultations -->
          <patient-consultations v-if="currentSection === 2" />
          <!-- Note Confidentielle -->
          <patient-note v-if="currentSection === 3 && patient" />
          <!-- DOCUMENTS -->
          <patient-documents v-if="currentSection === 4" />
        </div>
      </div>
    </main>
  </section>
</template>

<script>
// Components
import patientConsultations from "./components/patient_consultations.vue";
import patientInformation from "./components/patient_informations.vue";
import patientDocuments from "./components/patient_documents.vue";
import patientHelthbook from "@/views/dashboard/consultationsN/carnet_sante/index.vue";
import patientNote from "./components/patient_note.vue";
import { mapActions } from "vuex";

export default {
  components: {
    patientConsultations,
    patientInformation,
    patientDocuments,
    patientHelthbook,
    patientNote
  },

  data() {
    return {
      currentSection: 1,
      patient: null,
      sections: [
        {
          id: 1,
          name: "Carnet de sante",
          icon: `
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.333 2h4A2.667 2.667 0 0 1 8 4.667V14a2 2 0 0 0-2-2H1.333V2zM14.667 2h-4A2.667 2.667 0 0 0 8 4.667V14a2 2 0 0 1 2-2h4.667V2z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            `
        },
        {
          id: 2,
          name: "Consultations",
          icon: `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.333 2v1.333H4V6a2.666 2.666 0 1 0 5.333 0V3.333H8V2h2c.368 0 .667.299.667.667V6a4.001 4.001 0 0 1-3.334 3.945V11a2.333 2.333 0 0 0 4.517.825 2 2 0 1 1 1.366.099A3.668 3.668 0 0 1 6 11V9.945A4 4 0 0 1 2.667 6V2.667c0-.368.298-.667.666-.667h2zm7.334 7.333a.667.667 0 1 0 0 1.334.667.667 0 0 0 0-1.334z" fill="currentColor"/>
            </svg>
            `
        },
        {
          id: 3,
          name: "Note confidentielle",
          icon: `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#a)" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M6.6 2.827a6.08 6.08 0 0 1 1.4-.16C12.667 2.667 15.333 8 15.333 8a12.33 12.33 0 0 1-1.44 2.127m-4.48-.714a2 2 0 1 1-2.826-2.826m5.373 5.373A6.713 6.713 0 0 1 8 13.333C3.333 13.333.667 8 .667 8A12.3 12.3 0 0 1 4.04 4.04l7.92 7.92zM.667.667l14.666 14.666"/>
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h16v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
            `
        },
        {
          id: 4,
          name: "Documents partages",
          icon: `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.667 12.667A1.334 1.334 0 0 1 13.333 14H2.667a1.334 1.334 0 0 1-1.334-1.333V3.333A1.333 1.333 0 0 1 2.667 2H6l1.333 2h6a1.334 1.334 0 0 1 1.334 1.333v7.334z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `
        }
      ]
    };
  },
  methods: {
    ...mapActions({ getPatient: "client/GET_PATIENT" }),
    onPatientResponse({ data }) {
      this.patient = data;
    }
  },
  mounted() {
    this.getPatient({
      patientId: this.$route.params.patientId,
      onData: this.onPatientResponse
    });
  }
};
</script>
