<template>
  <!-- INFORMATIONS PATIENT -->
  <div
    id="cardInformation"
    class="bg-white rounded-lg py-[26px] px-[32px] flex flex-col gap-y-[43px]"
    :class="customClass"
  >
    <div id="sectionOoneInformation" class="flex flex-col gap-y-[24px]">
      <h1
        class="text-[#333638] text-[20px] font-EffraM flex items-center gap-x-1"
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              clip-path="url(#a)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666zM10 13.333V10"
                stroke="#111"
              />
              <path d="M10 6.667h.008" stroke="currentColor" />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
              </clipPath>
            </defs>
          </svg>
        </span>
        Informations
      </h1>
      <hr class="bg-[#eeeeee] h-px border-0" />
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-6">
        <!-- FULL NAME -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.333 14v-1.333A2.667 2.667 0 0 0 10.667 10H5.333a2.667 2.667 0 0 0-2.666 2.667V14M8 7.333A2.667 2.667 0 1 0 8 2a2.667 2.667 0 0 0 0 5.333z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-sm font-EffraM">Prenom & nom</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.firstName }} {{ patient.lastName }}
          </span>
        </div>
        <!-- PHONE NUMBER -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M14.667 11.28v2a1.332 1.332 0 0 1-1.454 1.333 13.193 13.193 0 0 1-5.753-2.046 13 13 0 0 1-4-4 13.193 13.193 0 0 1-2.047-5.78A1.333 1.333 0 0 1 2.74 1.333h2A1.333 1.333 0 0 1 6.073 2.48a8.56 8.56 0 0 0 .467 1.873 1.333 1.333 0 0 1-.3 1.407l-.847.847a10.666 10.666 0 0 0 4 4l.847-.847a1.334 1.334 0 0 1 1.407-.3 8.561 8.561 0 0 0 1.873.467 1.334 1.334 0 0 1 1.147 1.353z"
                    stroke="#767676"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span class="text-sm font-EffraM">Numero</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.mobile }}
          </span>
        </div>
        <!-- CIN -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clip-path="url(#a)"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M14 2.667H2C1.264 2.667.667 3.264.667 4v8c0 .736.597 1.333 1.333 1.333h12c.736 0 1.333-.597 1.333-1.333V4c0-.736-.597-1.333-1.333-1.333zM.667 6.667h14.666"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                  </clipPath>
                </defs>
              </svg>
            </span>

            <span class="text-sm font-EffraM">CIN</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.cin || "-" }}
          </span>
        </div>
        <!-- Langues -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.667 10s.666-.667 2.666-.667 3.334 1.334 5.334 1.334c2 0 2.666-.667 2.666-.667V2s-.666.667-2.666.667-3.334-1.334-5.334-1.334c-2 0-2.666.667-2.666.667v8zM2.667 14.667V10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-sm font-EffraM">Langues</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.landingPage.languages.join(", ") }}
          </span>
        </div>
        <!-- Birthday & BirthdayPlace -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.667 1.333V4M5.333 1.333V4M2 6.667h12"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-sm font-EffraM">Date & lieu de naissance</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.birthday | moment("DD/MM/YYYY") }}
            {{ patient.birthplace }}
          </span>
        </div>
        <!-- Email Adderss -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.667 2.456h10.666c.734 0 1.334.553 1.334 1.228v7.369c0 .675-.6 1.228-1.334 1.228H2.667c-.734 0-1.334-.553-1.334-1.229V3.684c0-.675.6-1.228 1.334-1.228z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.667 3.684 8 7.983 1.333 3.684"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>

            <span class="text-sm font-EffraM">Email</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.email }}
          </span>
        </div>
        <!-- Job -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.333 4.667H2.667c-.737 0-1.334.597-1.334 1.333v6.667c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V6c0-.736-.597-1.333-1.334-1.333z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.667 14V3.333A1.333 1.333 0 0 0 9.333 2H6.667a1.333 1.333 0 0 0-1.334 1.333V14"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>

            <span class="text-sm font-EffraM">Profession</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ patient.job || "-" }}
          </span>
        </div>
        <!-- Relationship -->
        <div class="flex flex-col gap-y-1">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clip-path="url(#a)"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M10.667 14v-1.333A2.667 2.667 0 0 0 8 10H3.333a2.667 2.667 0 0 0-2.666 2.667V14M5.667 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333zM15.333 7.333h-4"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                  </clipPath>
                </defs>
              </svg>
            </span>

            <span class="text-sm font-EffraM">Etat civil</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM">
            {{ $FaSituation(patient.relationship) || "-" }}
          </span>
        </div>
        <!-- Address -->
        <div class="flex flex-col gap-y-1 col-span-1 xl:col-span-2">
          <div class="flex flex-row items-center gap-x-1 text-[#767676]">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-map-pin"
              >
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>
            </span>
            <span class="text-sm font-EffraM">Adresse</span>
          </div>
          <span class="text-sm text-[#111111] font-EffraM underline">
            {{ patient.landingPage.address.label }}
            {{
              patient.landingPage.address.city &&
              patient.landingPage.address.city.name
            }}
            {{ patient.landingPage.address.zipCode }}
          </span>
        </div>
      </div>
    </div>

    <div id="sectionTwoAssurance" class="flex flex-col gap-y-[24px]">
      <h1
        class="text-[#333638] text-[20px] font-EffraM flex items-center gap-x-1"
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 18.333S16.667 15 16.667 10V4.167L10 1.667l-6.667 2.5V10c0 5 6.667 8.333 6.667 8.333z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>

        Assurance
      </h1>
      <hr class="bg-[#eeeeee] h-px border-0" />
      <!-- <div class="flex flex-row items-center text-[#767676]">
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 5 5 15M5 5l10 10"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>

        <span class="font-EffraM text-base">{{
          patient.insurance
            ? `${patient.insurance.name}: ${patient.insurance.numPolice}`
            : "-"
        }}</span>
      </div> -->

      <div class="grid grid-cols-2 gap-x-4 gap-y-[24px]">
        <!-- Assurance complémentaire -->
        <div class="flex flex-col gap-y-1">
          <span class="font-EffraM text-[14px] text-[#767676]"
            >Assurance complémentaire</span
          >
          <span class="font-EffraR font-bold text-[14px] text-black">
            {{
              `${
                patient.insurance && patient.insurance.name
                  ? patient.insurance.name
                  : "--"
              }`
            }}
          </span>
        </div>
        <!-- N° d'affiliation CNSS -->
        <div class="flex flex-col gap-y-1">
          <span class="font-EffraM text-[14px] text-[#767676]"
            >N° d'affiliation CNSS</span
          >
          <span class="font-EffraR font-bold text-[14px] text-black">
            {{ patient.CNSS || "--" }}
          </span>
        </div>
        <!-- Société contractante -->
        <div class="flex flex-col gap-y-1">
          <span class="font-EffraM text-[14px] text-[#767676]"
            >Société contractante</span
          >
          <span class="font-EffraR font-bold text-[14px] text-black">
            {{
              patient.insurance && patient.insurance.company
                ? patient.insurance.company
                : "--"
            }}
          </span>
        </div>
        <!-- N° d'adhésion -->
        <div class="flex flex-col gap-y-1">
          <span class="font-EffraM text-[14px] text-[#767676]"
            >N° d'adhésion</span
          >
          <span class="font-EffraR font-bold text-[14px] text-black">
            {{
              patient.insurance && patient.insurance.numAdhesion
                ? patient.insurance.numAdhesion
                : "--"
            }}
          </span>
        </div>
        <!-- N° d'adhésion -->
        <div class="flex flex-col gap-y-1">
          <span class="font-EffraM text-[14px] text-[#767676]"
            >N° d'police</span
          >
          <span class="font-EffraR font-bold text-[14px] text-black">
            {{
              patient.insurance && patient.insurance.numPolice
                ? patient.insurance.numPolice
                : "--"
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customClass", "patient"],
  mounted() {
    console.log(this.patient);
  },
};
</script>
