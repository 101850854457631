<template>
  <section id="documentsPatient">
    <!-- LIST OF FILES -->
    <!-- CONSULTATIONS PATIENT -->
    <div
      id="cardConsultations"
      class="flex flex-col gap-y-3"
      v-if="files.length"
    >
      <div
        class="
          bg-[#f3f6fb]
          rounded-lg
          h-[67px]
          p-[24px]
          hidden
          lg:grid
          grid-cols-5
          gap-x-1
          items-center
        "
      >
        <div class="font-EffraR text-sm text-[#232332] col-span-2">
          Date et heure
        </div>
        <div class="font-EffraR text-sm text-[#232332]">Nom</div>
        <div class="font-EffraR text-sm text-[#232332]">Type Document</div>
        <div class="font-EffraR text-sm text-[#232332]">Actions</div>
      </div>
      <!-- LIST -->
      <div
        class="
          border border-solid border-[#e7ecf2]
          rounded-lg
          px-[24px]
          grid grid-cols-1
          lg:grid-cols-5
          gap-y-5 gap-x-5
          items-center
          h-auto
          lg:h-[64px]
          py-5
          lg:py-0
        "
        v-for="(file, index) in files"
        :key="index"
      >
        <div class="font-EffraR text-sm text-black col-span-1 lg:col-span-2">
          {{ formatDate(file.created_at) }}
        </div>
        <div class="font-EffraR text-sm text-black">
          {{ file.name }}
        </div>
        <div class="font-EffraR text-sm text-black">
          {{ file.category.title }}
        </div>
        <div class="flex flex-row gap-x-[12px]">
          <dok-button
            size="md"
            bg="white"
            custom-class="flex items-center gap-x-1"
            @click.native="onDownloadClick(file.uid)"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                clip-path="url(#a)"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z"
                />
                <path
                  d="M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h18v18H0z" />
                </clipPath>
              </defs>
            </svg>

            Voir
          </dok-button>

          <dok-button
            size="md"
            v-if="file.metadata.uploaderRefId === $auth.getUserId()"
            @click.native="onDeleteFile(file._id, index)"
            bg="white"
            custom-class="flex text-red-500 items-center gap-x-1"
          >
            Supprimer
          </dok-button>
        </div>
      </div>
    </div>

    <!-- IF EMPTY DATA -->
    <div
      class="
        flex flex-col
        gap-y-[32px]
        items-center
        justify-center
        overflow-hidden
      "
      v-if="!files.length"
    >
      <img src="/svg/null_data_search.svg" />

      <div class="flex flex-col items-center gap-y-3">
        <h1 class="text-[20px] font-EffraM text-black">
          Aucun document partagé trouvé
        </h1>
        <span class="text-base text-center text-black font-EffraR">
          Documents que vous avez partagés ou quelque chose <br />
          apparaîtra ici
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      data: [],
      files: [],
    };
  },
  methods: {
    ...mapActions({
      getFiles: "file/GET_BY_CLIENT",
      deleteFile: "file/DELETE_FILE",
    }),
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY à HH:mm");
    },
    onDownloadClick(documentId) {
      window.open(
        `https://api.dok.ma/api/v1/download/document/${documentId}`,
        "_blank"
      );
    },
    onDeleteFile(fileId, index) {
      console.log({ fileId });
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteFile({
              id: fileId,
              onData: ({ ok, message }) => {
                if (ok) this.files.splice(index, 1);
                this.$vs.notify({
                  time: 4000,
                  text: message,
                  color: ok ? "success" : "danger",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              },
            });
          }
        },
      });
    },
  },
  mounted() {
    this.getFiles({
      onData: ({ data }) => {
        this.files = data;
      },
      params: {
        user: this.$route.params.patientId,
      },
    });
  },
};
</script>
