<template>
  <!-- CONSULTATIONS PATIENT -->
  <div id="cardConsultations" class="flex flex-col gap-y-6">
    <template v-if="!loader">
      <div
        class="flex flex-row gap-x-[11px] items-center rounded-t-lg px-[30px] h-[56px] bg-[#deecff] text-[#297afb]"
      >
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="13" r="13" fill="#297AFB" />
          <g
            clip-path="url(#a)"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M11.95 9.12A4.56 4.56 0 0 1 13 9c3.5 0 5.5 4 5.5 4a9.25 9.25 0 0 1-1.08 1.595m-3.36-.535a1.5 1.5 0 1 1-2.12-2.12m4.03 4.03A5.035 5.035 0 0 1 13 17c-3.5 0-5.5-4-5.5-4a9.225 9.225 0 0 1 2.53-2.97l5.94 5.94zM7.5 7.5l11 11"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" transform="translate(7 7)" d="M0 0h12v12H0z" />
            </clipPath>
          </defs>
        </svg>

        <span class="font-EffraM text-sm">
          Cette note est uniquement visible par le praticien qui l'a rédigé
        </span>
      </div>
      <div class="flex flex-col gap-y-4 pb-[32px]">
        <!-- SPEACH TO TEXT -->
        <div class="flex flex-col gap-y-1 px-[32px]">
          <label for="noteLabel" class="font-EffraR text-base text-black">
            Note confidentielle
          </label>

          <speach-textarea
            id="spechText"
            :content.sync="note"
            holder="Insert text here or dictate by clicking on the button below"
          />
        </div>
        <!-- Actions -->
        <div class="flex justify-end px-[32px] w-full">
          <dok-button
            size="md"
            @click.native="onSave"
            custom-class="px-8 flex items-center justify-center"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m20.5 6-11 11-5-5"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Sauvegarder
          </dok-button>
        </div>
      </div>
    </template>
    <template v-if="loader">
      <div class="w-full px-2 py-5 flex items-center justify-center">
        <loader />
      </div>
    </template>
  </div>
</template>

<script>
import speachTextarea from "@/views/global-components/spechToTextarea";
import loader from "@/views/global-components/loader";
import { mapActions } from "vuex";

export default {
  components: {
    speachTextarea,
    loader
  },

  data() {
    return {
      note: "",
      loader: true
    };
  },
  methods: {
    ...mapActions({
      getNote: "dmp/GET_NOTE_CONFIDENTIELLE_PATIENT",
      saveNote: "dmp/SAVE_NOTE_CONFIDENTIELLE_PATIENT"
    }),
    onSave() {
      this.$vs.loading();
      this.saveNote({
        patientId: this.$route.params.patientId,
        note: this.note,
        onData: ({ ok, message }) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 4000,
            text: ok
              ? "Mis à jour avec succès"
              : "Il y a un problème avec le traitement",
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
      });
    }
  },
  created() {
    this.getNote({
      patientId: this.$route.params.patientId,
      onData: ({ data }) => {
        console.log(data);

        this.note = data.note;

        this.$nextTick(() => {
          this.loader = false;
        });
      }
    });
  }
};
</script>
