<template>
  <!-- CONSULTATIONS PATIENT -->
  <div id="cardConsultations" class="flex flex-col gap-y-3">
    <template v-if="!loader">
      <div
        class="
          bg-[#f3f6fb]
          rounded-lg
          h-[67px]
          p-[24px]
          hidden
          md:grid
          grid-cols-6
          gap-x-1
          items-center
        "
      >
        <div class="font-EffraR text-sm text-[#232332]">Date et heure</div>
        <div class="font-EffraR text-sm text-[#232332]">Suivi medical</div>
        <div class="font-EffraR text-sm text-[#232332]">Documents</div>
        <div class="font-EffraR text-sm text-[#232332]">Status</div>
        <div class="font-EffraR text-sm text-[#232332]">Actions</div>
      </div>
      <!-- LIST -->
      <div
        class="
          border border-solid border-[#e7ecf2]
          rounded-lg
          px-[24px]
          grid grid-cols-1
          lg:grid-cols-6
          gap-x-1 gap-y-5
          items-center
          h-auto
          lg:h-[64px]
          py-5
          lg:py-0
        "
        v-for="(consultation, index) in consultations"
        :key="index"
      >
        <div class="font-EffraR text-sm text-black">
          {{ formatDate(consultation.startAt) }}
        </div>
        <div>
          <toggle :is-active.sync="consultation.hasFollowUp"></toggle>
        </div>
        <div>
          <a
            :href="`https://api.dok.ma/api/v1/download/document/${
              consultation.prescription._id
            }?token=${$auth.getToken()}`"
            v-if="consultation.prescription"
            class="
              py-[5px]
              px-[8px]
              rounded-md
              text-dokBlue-ultra
              border-2 border-solid border-dokBlue-ultra
              font-EffraM
              text-[12px]
            "
          >
            Prescription
          </a>
          <a
            :href="`https://api.dok.ma/api/v1/download/document/${
              consultation.analysisResult._id
            }?token=${$auth.getToken()}`"
            v-if="consultation.analysisResult"
            class="
              py-[5px]
              px-[8px]
              rounded-md
              text-dokBlue-ultra
              border-2 border-solid border-dokBlue-ultra
              font-EffraM
              text-[12px]
            "
          >
            Résultats d'analyse
          </a>
          <span
            v-if="!consultation.analysisResult && !consultation.prescription"
            >-</span
          >
        </div>
        <div class="font-EffraR text-sm text-black">
          {{ $statusConsultation(consultation.status) }}
        </div>
        <div
          class="
            flex flex-row
            items-center
            gap-x-[12px]
            col-span-1
            lg:col-span-2
          "
        >
          <dok-button
            size="md"
            bg="bBlue"
            custom-class="flex items-center gap-x-1 px-3 xl:px-8"
            @click.native="modalInformation(consultation._id)"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                clip-path="url(#a)"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z"
                />
                <path
                  d="M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h18v18H0z" />
                </clipPath>
              </defs>
            </svg>

            Consulter
          </dok-button>

          <share-consultation :data="consultation" />
        </div>
      </div>

      <!-- If empty date -->
      <div
        class="flex items-center justify-center pt-10 pb-5"
        v-if="!consultations.length"
      >
        <img src="/svg/null_data.svg" style="max-height: 350px" />
      </div>

      <!-- MOOOOOOOOOOOOOOODAL -->
      <modal
        :isActive="modal"
        :exitModal="
          () => {
            modal = false;
            idOpenConsultation = null;
          }
        "
        :width="800"
        :hidden-header="true"
        custom-class-mobile="bg-[#f0f0f0]"
        custom-class="bg-[#f0f0f0]"
      >
        <div class="px-2">
          <resume-consultation :consultationID="idOpenConsultation" />
        </div>
      </modal>
    </template>
    <template v-if="loader">
      <div class="w-full px-2 py-5 flex items-center justify-center">
        <loader />
      </div>
    </template>
  </div>
</template>

<script>
import toggle from "@/views/dashboard/consultationsN/globalComponents/switchRadio.vue";
import loader from "@/views/global-components/loader";
import modal from "@/views/global-components/dragModal";
import shareConsultation from "@/views/global-components/consultation/share.vue";
import resumeConsultation from "@/views/global-components/ConsultationSummary";
import moment from "moment";

export default {
  components: {
    toggle,
    loader,
    modal,
    resumeConsultation,
    shareConsultation,
  },
  props: ["customClass"],
  data() {
    return {
      loader: true,
      modal: false,
      consultations: [],
      idOpenConsultation: null,
    };
  },
  methods: {
    modalInformation(id) {
      this.idOpenConsultation = id;

      this.$nextTick(() => {
        this.modal = true;
      });
    },
    async getConsultation() {
      this.$store.dispatch("dmp/GET_CONSULTATIONS", {
        // page: this.page,
        patientId: this.$route.params.patientId,
        onData: this.onConsultationsResponse,
      });
    },
    onConsultationsResponse({ data }) {
      this.consultations = data;

      this.$nextTick(() => {
        this.loader = false;
      });
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY à HH:mm");
    },
  },
  mounted() {
    this.getConsultation();
  },
};
</script>
